import { IWixAPI } from '@wix/yoshi-flow-editor';
import { createNotFoundPageRouter } from '../../common/controller/create-router';
import { Router } from '../../common/router';
import { isFeedAdapterEnabled } from '../../common/selectors/experiment-selectors';
import { FEED_PAGE_NOT_FOUND } from '../../common/services/detect-route';
import { AppStore } from '../../common/types';
import {
  ROUTE_404,
  ROUTE_ARCHIVE,
  ROUTE_CATEGORIES,
  ROUTE_CATEGORY,
  ROUTE_HASHTAG,
  ROUTE_HOME,
  ROUTE_LOGIN,
  ROUTE_SEARCH,
  ROUTE_TAGS,
} from '../constants/routes';
import { createArchivePageRouter } from './create-routers/create-archive-page-router';
import { createArchivePageRouterWithAdapter } from './create-routers/create-archive-page-router-with-adapter';
import { createCategoryPageRouter } from './create-routers/create-category-page-router';
import { createCategoryPageRouterWithAdapter } from './create-routers/create-category-page-router-with-adapter';
import { createFeedPageRouter } from './create-routers/create-feed-page-router';
import { createFeedPageRouterWithAdapter } from './create-routers/create-feed-page-router-with-adapter';
import { createHashtagPageRouter } from './create-routers/create-hashtag-page-router';
import { createHashtagPageRouterWithAdapter } from './create-routers/create-hashtag-page-router-with-adapter';
import { createSearchPageRouter } from './create-routers/create-search-page-router';
import { createTagPageRouter } from './create-routers/create-tag-page-router';
import { createTagPageRouterWithAdapter } from './create-routers/create-tag-page-router-with-adapter';

export const createRouter = (
  store: AppStore,
  _config: unknown,
  wixCodeApi: IWixAPI,
) => {
  const router = new Router();
  const withAdapter = isFeedAdapterEnabled(
    store.getState(),
    wixCodeApi.window.viewMode,
  );

  router.add(
    ROUTE_TAGS,
    withAdapter
      ? createTagPageRouterWithAdapter(store, wixCodeApi)
      : createTagPageRouter(store, wixCodeApi),
    { preFetch: withAdapter },
  );
  router.add(
    ROUTE_CATEGORIES,
    withAdapter
      ? createCategoryPageRouterWithAdapter(store, wixCodeApi)
      : createCategoryPageRouter(store, wixCodeApi),
    { preFetch: true },
  );

  router.add(
    ROUTE_CATEGORY,
    withAdapter
      ? createCategoryPageRouterWithAdapter(store, wixCodeApi)
      : createCategoryPageRouter(store, wixCodeApi),
    { preFetch: true },
  );
  router.add(ROUTE_SEARCH, createSearchPageRouter(store, wixCodeApi));
  router.add(
    ROUTE_HASHTAG,
    withAdapter
      ? createHashtagPageRouterWithAdapter(store, wixCodeApi)
      : createHashtagPageRouter(store, wixCodeApi),
    { preFetch: withAdapter },
  );
  router.add(
    ROUTE_ARCHIVE,
    withAdapter
      ? createArchivePageRouterWithAdapter(store, wixCodeApi)
      : createArchivePageRouter(store, wixCodeApi),
    { preFetch: withAdapter },
  );
  router.add(
    ROUTE_HOME,
    withAdapter
      ? createFeedPageRouterWithAdapter({
          store,
          wixCodeApi,
          initialLoad: true,
        })
      : createFeedPageRouter(store, wixCodeApi),
    { preFetch: true },
  );
  router.add(
    ROUTE_404,
    createNotFoundPageRouter(store, wixCodeApi, FEED_PAGE_NOT_FOUND, ROUTE_404),
  );
  router.add(ROUTE_LOGIN);
  router.fallback(ROUTE_404);
  return router;
};
