import { applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { postMetadataMiddleware } from '../../common/middleware/post-metadata-middleware';
import createRequests from '../../common/services/create-requests';
import createStore from '../../common/services/create-store';
import { waitForActionMiddleware } from '../../common/store/wait-for-action/wait-for-action-middleware';
import {
  AppStore,
  ControllerConfig,
  FedopsLogger,
  FlowAPI,
} from '../../common/types';
import { SET_MAIN_POST } from '../store/main-post';
import { reducers } from '../store/reducers';

type CreateReduxStoreParams = {
  fedopsLogger: FedopsLogger;
  isSSR: boolean;
  language: string;
  bundleName: string;
  controllerConfig: ControllerConfig;
  flowAPI: FlowAPI;
};

export function createReduxStore({
  fedopsLogger,
  isSSR,
  language,
  bundleName,
  controllerConfig,
  flowAPI,
}: CreateReduxStoreParams) {
  const { appParams, compId, wixCodeApi } = controllerConfig;

  const p: { store?: AppStore } = {};
  const getStore = () => p.store as AppStore;

  const { request } = createRequests({
    appParams,
    wixCodeApi,
    bundleName,
    getStore,
    flowAPI,
  });

  const middleware = [
    thunk.withExtraArgument({
      request,
      wixCodeApi,
      compId,
      appParams,
      fedopsLogger,
      httpClient: flowAPI.httpClient,
    }),
    waitForActionMiddleware([SET_MAIN_POST]),
    postMetadataMiddleware({ isSSR, language, localizePosts: true }),
  ];

  const initialState = { experiments: flowAPI.essentials.experiments.all() };

  p.store = createStore(reducers, initialState, applyMiddleware(...middleware));

  return p.store as AppStore;
}
